@import '~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  background-color: #fbfdff;
  min-height: 100vh;
  max-height: 100vh;

  &.is-in-iframe {
    background-color: white;
  }
}

a:hover {
  text-decoration: none;
}

.utiligize-table {
  font-size: 13.5px;
  font-weight: 400;
  color: #000;
  background: white;

  & thead th {
    border-top: none;
    border-bottom-width: 1px;
    position: relative;
    vertical-align: middle;
    padding-left: 8px;

    &.sorting_enabled {
      padding-right: 30px;
    }
  }

  & td {
    padding: 7px 8px !important;
    vertical-align: middle;

    &:last-child:not(.text-break) {
      white-space: nowrap;
    }
  }
}

.range-slider__wrap .range-slider__tooltip {
  z-index: 1040; // Bootstrap modal with 1050 must be on top
}

.cursor-none,
.cursor-none > label,
.cursor-none > input:first-child {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.control-sidebar,
.control-sidebar::before {
  right: -350px;
  width: 350px;
}

.tab-pane {
  position: relative;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fbfdff;
}

.switch-group .switch-on.btn.btn-primary:not(:hover) {
  background-color: #0f7ce0;
}

.modal-footer > * {
  margin: revert;
}

table.dataTable {
  margin: 0 !important;
}

.tooltip {
  font-family: inherit;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 500;
  font-size: 14px;
}
